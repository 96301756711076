// src/utils/seoConfig.js
export const defaultSEO = {
    title: "GetSetPickle - One Stop Destination for Pickleball",
    description: "Find gears, resources and be better at pickleball. Your comprehensive guide to everything pickleball.",
    openGraph: {
      type: 'website',
      locale: 'en_US',
      url: 'https://getsetpickle.com/',
      site_name: 'GetSetPickle',
    },
    twitter: {
      handle: '@getsetpickle',
      site: '@getsetpickle',
      cardType: 'summary_large_image',
    },
  };
  
  export const pageSEO = {
    '/': {
      title: "GetSetPickle - One Stop Destination for Pickleball",
      description: "Find gears, resources and be better at pickleball. Your comprehensive guide to everything pickleball.",
      canonical: "https://getsetpickle.com/",
      keywords: "pickleball, pickleball gear, pickleball guides, pickleball resources",
    },
    '/gears': {
      title: "Pickleball Gear Guide - GetSetPickle",
      description: "Comprehensive guide to pickleball gear, equipment, and accessories. Find the best paddles, balls, and more.",
      canonical: "https://getsetpickle.com/gears",
      keywords: "pickleball gear, pickleball paddles, pickleball balls, pickleball equipment",
    },
    '/guides': {
      title: "Pickleball Guides & Resources - GetSetPickle",
      description: "Learn pickleball with our comprehensive guides, tutorials, and resources. From beginner to advanced techniques.",
      canonical: "https://getsetpickle.com/guides",
      keywords: "pickleball guides, pickleball tutorials, pickleball tips, pickleball learning",
    }
  };