import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ title, description, link, imageSrc }) => {
  return (
    <Link to={link} className="block no-underline text-inherit">
      <div className="rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
        <img 
          src={imageSrc} 
          alt={title} 
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </Link>
  );
};

// Make sure to use default export
export default Card;