import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/common/MetaTags';

const Guides = () => {
  const location = useLocation();
  
  // Handle hash link scrolling
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const guides = [
    {
      title: "Basic Rules",
      description: "Essential rules every pickleball player should know",
      readTime: "5 min read",
      difficulty: "Beginner",
      comingSoon: true
    },
    {
      title: "Serving Techniques",
      description: "Master the art of serving in pickleball",
      readTime: "8 min read",
      difficulty: "Intermediate",
      comingSoon: true
    },
    {
      title: "Court Positioning",
      description: "Learn proper court positioning and movement",
      readTime: "6 min read",
      difficulty: "Beginner",
      comingSoon: true
    }
  ];

  const crashCourseModules = [
    {
      title: "Module 1: Getting Started",
      description: "Introduction to pickleball basics",
      duration: "30 min",
      topics: ["Equipment overview", "Basic rules", "Court layout"]
    },
    {
      title: "Module 2: Basic Techniques",
      description: "Essential shots and movements",
      duration: "45 min",
      topics: ["Dinking", "Serving", "Return of serve"]
    },
    {
      title: "Module 3: Strategy",
      description: "Fundamental game strategies",
      duration: "60 min",
      topics: ["Court positioning", "Shot selection", "Partner communication"]
    }
  ];

  return (
    <div className="pt-20 px-4">
      <MetaTags 
        customMeta={{
          title: "Pickleball Guides & Resources - GetSetPickle",
          description: "Learn pickleball with our comprehensive guides and tutorials"
        }}
      />
      
      {/* Guides Section */}
      <section className="max-w-screen-xl mx-auto mb-16">
        <h1 className="text-4xl font-bold mb-6">Pickleball Guides</h1>
        <p className="text-xl text-gray-600 mb-8">
          Comprehensive guides to help you master pickleball
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {guides.map((guide, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-xl font-bold">{guide.title}</h3>
                  {guide.comingSoon && (
                    <span className="bg-pickle-purple text-white text-xs px-2 py-1 rounded">
                      Coming Soon
                    </span>
                  )}
                </div>
                <p className="text-gray-600 mb-4">{guide.description}</p>
                <div className="flex justify-between text-sm text-gray-500">
                  <span>{guide.readTime}</span>
                  <span>{guide.difficulty}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      
      {/* Crash Course Section */}
      <section id="crash-course" className="max-w-screen-xl mx-auto pt-16">
        <div className="bg-gradient-to-r from-pickle-blue to-pickle-purple text-white rounded-lg p-8 mb-12">
          <h2 className="text-3xl font-bold mb-4">Pickleball Crash Course</h2>
          <p className="text-xl opacity-90 mb-6">
            Master the fundamentals of pickleball in our comprehensive crash course
          </p>
          <button className="bg-white text-pickle-purple px-6 py-3 rounded-md font-medium hover:bg-opacity-90 transition-colors duration-200">
            Start Learning
          </button>
        </div>
        
        <div className="space-y-8">
          {crashCourseModules.map((module, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-bold">{module.title}</h3>
                <span className="text-gray-500">{module.duration}</span>
              </div>
              <p className="text-gray-600 mb-4">{module.description}</p>
              <div className="flex flex-wrap gap-2">
                {module.topics.map((topic, topicIndex) => (
                  <span 
                    key={topicIndex}
                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                  >
                    {topic}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Guides;