// src/components/common/MetaTags.js
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { defaultSEO, pageSEO } from '../../utils/seoConfig';

const MetaTags = ({ customMeta }) => {
  const location = useLocation();
  const meta = {
    ...defaultSEO,
    ...(pageSEO[location.pathname] || {}),
    ...customMeta,
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={meta.canonical || `https://getsetpickle.com${location.pathname}`} />
      
      {/* Open Graph */}
      <meta property="og:url" content={`https://getsetpickle.com${location.pathname}`} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content={meta.openGraph.type} />
      <meta property="og:site_name" content={meta.openGraph.site_name} />
      
      {/* Twitter */}
      <meta name="twitter:card" content={meta.twitter.cardType} />
      <meta name="twitter:site" content={meta.twitter.site} />
      <meta name="twitter:creator" content={meta.twitter.handle} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      
      {/* Additional SEO tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="robots" content="index, follow" />
      
      {/* Preconnect to important third-party domains */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      
      {/* Google font (DM Sans) */}
      <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
    </Helmet>
  );
};

export default MetaTags;