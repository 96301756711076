import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-colors duration-300 ${
      isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
    }`}>
      <div className="max-w-screen-xl mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-gray-900">
          GetSetPickle
        </Link>
        
        <div className="relative">
          <button
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
            className={`px-4 py-2 rounded-md transition-colors ${
              isScrolled ? 'text-gray-900 hover:bg-gray-100' : 'text-white hover:bg-white/10'
            }`}
          >
            Resources
          </button>
          
          {isDropdownOpen && (
            <div
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
            >
              <Link to="/gears" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Gears
              </Link>
              <Link to="/guides" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Guides
              </Link>
              <Link to="/guides#crash-course" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Crash Course
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;