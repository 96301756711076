import React from 'react';
import Hero from '../components/sections/Hero';
import CardsSection from '../components/sections/CardsSection';

const Home = () => {
  return (
    <>
      <Hero />
      <CardsSection />
    </>
  );
};

// Make sure to use default export
export default Home;