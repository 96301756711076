import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-pickle-blue text-white">
      <div className="max-w-screen-xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Social Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex flex-wrap gap-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">Facebook</a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">Instagram</a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">TikTok</a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">Twitter</a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">YouTube</a>
            </div>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Legal</h3>
            <div className="flex flex-wrap gap-4">
              <Link to="/privacy-policy" className="hover:text-gray-200">Privacy Policy</Link>
              <Link to="/terms-of-use" className="hover:text-gray-200">Terms of Use</Link>
              <Link to="/cookie-policy" className="hover:text-gray-200">Cookie Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;