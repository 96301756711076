import React from 'react';
import MetaTags from '../components/common/MetaTags';

const Gears = () => {
  return (
    <div className="pt-20 px-4">
      <MetaTags 
        customMeta={{
          title: "Pickleball Gears - GetSetPickle",
          description: "Discover the best pickleball gears and equipment recommendations"
        }}
      />
      <div className="max-w-screen-xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">Pickleball Gears</h1>
        <p className="text-xl text-gray-600 mb-8">
          Everything and anything you want to know about Pickleball gears
        </p>
        
        {/* Add your gears content here */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Placeholder content */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-2xl font-bold mb-4">Paddles</h2>
            <p className="text-gray-600">Coming soon...</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-2xl font-bold mb-4">Balls</h2>
            <p className="text-gray-600">Coming soon...</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-2xl font-bold mb-4">Accessories</h2>
            <p className="text-gray-600">Coming soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Make sure to use default export
export default Gears;