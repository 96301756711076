import React from 'react';
import Card from '../common/Card';

const CardsSection = () => {
  const cards = [
    {
      title: 'Gears',
      description: 'Everything and anything you want to know about Pickleball gears',
      link: '/gears',
      imageSrc: '/assets/images/gears.jpg'
    },
    {
      title: 'Guides',
      description: 'Everything and anything you want to know about Pickleball gears',
      link: '/guides',
      imageSrc: '/assets/images/guides.jpg'
    },
    {
      title: 'Crash Course',
      description: 'Everything and anything you want to know about Pickleball gears',
      link: '/guides#crash-course',
      imageSrc: '/assets/images/crash-course.jpg'
    }
  ];

  return (
    <section className="max-w-screen-xl mx-auto py-16 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </section>
  );
};

// Make sure to use default export
export default CardsSection;